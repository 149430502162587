<div class="me-2 pe-3 border-right-1 align-self-baseline">
  <div class="label-text">
    <app-icon [icon]="icon" [label]="label" ></app-icon>
    {{label | translate}}
  </div>
  <p class="p-text mb-0 ms-4 ps-1">
    @if(content || user){
      @if(isDateTimeFormat){
        {{content | dateTimeFormat }}
      }@else {
        @if(isBadge){
          <span class="{{ getClass( bg ? bg : '')}}">{{ content | translate}}</span>
        }@else if(user){
          <div class="user-widget d-flex">
            <app-user-image
              [imageUrl]="user.profile_image_path"
              [initial]="user.initial"
              [type]="'small'"
              [color]="user.color">
            </app-user-image>
            <span class="user-name ms-2 mt-1">{{user.first_name}} {{user.last_name}}</span>
          </div>
        }@else{
          {{content | translate}}
        }
      }
    }@else{
      {{default | translate }}
    }
  </p>
</div>
