import { Component, Input } from '@angular/core';
import { IconComponent } from '../../widgets/icon/icon.component';
import { DateTimeFormatPipe } from '../../../../../core/pipes/date-time-format.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { UserMinimalDTO } from '../../../../../features/organization/users/dto/user.dto';
import { UserImageComponent } from '../../widgets/user-image/user-image.component';
import { getStatusLabelClass } from '../../../../../core/modals/status.model';

@Component({
  selector: 'app-label-with-icon',
  standalone: true,
  imports: [
    IconComponent,
    DateTimeFormatPipe,
    TranslateModule,
    UserImageComponent,
  ],
  templateUrl: './label-with-icon.component.html',
  styleUrl: './label-with-icon.component.scss',
})
export class LabelWithIconComponent<T> {
  @Input() icon: string;
  @Input() label: string;
  @Input() content: any;
  @Input() default: string = '--';
  @Input() isDateTimeFormat: boolean = false;
  @Input() isBadge: boolean = false;
  @Input() user: UserMinimalDTO | null;
  @Input() bg: string | undefined;

  getClass(color: string) {
    return getStatusLabelClass(color);
  }
}
